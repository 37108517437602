import React from 'react'
import translationSwitchStyles from '../translationswitch.module.scss'

export const TwoStateSwitch = ({ onClick, label }) => (
         <span
           onClick={onClick}
           className={translationSwitchStyles.translationSwitch}
         >
           {label}
         </span>
       )