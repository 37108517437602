import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Head } from 'gatsby-plugin-i18next';


import {HELMET_DEFAULT} from '../../constants'

// TODO: make better seo
const MetaInfo = () => <Fragment><Head hreflang/><Helmet {...HELMET_DEFAULT}/></Fragment>

export default MetaInfo