const cookiesEnglish =  {
    message:'This website uses cookies to offer you better user experience. Find out more how we use cookies.',
    buttonText: 'I understand',
}

  const cookiesSlovak =  {
    message:'Táto stránka využíva cookies aby Vám poskytla lepšiu použiteľnosť. Zistiť viac ako využívame cookies.',
    buttonText: 'Rozumiem',
  }


  const cookies = {
      'sk': cookiesSlovak,
      'en': cookiesEnglish,
  }

  export default cookies