import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-i18next'

import navigationStyles from './navigation.module.scss'

const MenuItem = ({ label, to, closeNavigation }) => {
  return (
    <div className={navigationStyles.navigation__item}>
      <Link to={to} onClick={closeNavigation}>{label}</Link>
    </div>
  )
}

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default MenuItem
