import React, {Fragment} from 'react'

import '../assets/scss/main.scss'

import { translate } from 'react-i18next';

import Header from '../components/Header'
import Footer from '../components/Footer'
import MetaInfo from '../components/MetaInfo';

import menuItems from '../content/navigation'
import cookies from '../content/cookies'
import { getContentTranslation } from '../helpers'
import CookieBar from '../components/CookieBar';


const MainLayout = ({children, i18n}) => {
        const {language} = i18n

        const cookieBarProps = getContentTranslation(cookies, language) 

        return (
            <Fragment>
                <div className={`body`}>
                    <MetaInfo />
                    <div id="wrapper">
                        <Header menuItems={getContentTranslation(menuItems, language)}/>
                            <div id="main" className="alt">
                                {children}
                            </div>
                        <Footer />
                    </div>
                </div>
            <CookieBar {...cookieBarProps}></CookieBar>
            </Fragment>
        )
    }

export default translate()(MainLayout);