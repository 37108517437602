import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Logo from './Logo'
import Navigation from './Navigation'
import TranslationSwitch from './TranslationSwitch'
class Header extends Component {
  state = {
    isVisible: false,
  }

  toggleMenu = isVisible  => {
    this.setState({ isVisible })
  }
  
  get isVisible() {
    return this.state.isVisible
  }

  get classIsVisible() {
    return this.state.isVisible ? 'show' : ''
  }

  render() {
    const { classIsVisible, isVisible } = this
    const { menuItems } = this.props
    return (
      <header id="header" className="alt">
        <Logo showLabel/>
        <div className="wrapper-nav-right">
          <div className={`wrapper-navigation ${classIsVisible}`}>
            <Navigation menuItems={menuItems} closeNavigation={() => this.toggleMenu(!isVisible)}/>
          </div>
          <TranslationSwitch />
          <nav className="wrapper-menu" onClick={() => this.toggleMenu(!isVisible)}>
            <a className="menu-link">
              Menu
            </a>
          </nav>
        </div>
      </header>
    )
  }
}

Header.defaultProps = {
  menuItems: [],
}

Header.propTypes = {
  menuItems: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  })
}

export default Header
