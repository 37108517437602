import React from 'react'
import MenuItem from './MenuItem'

import navigationStyles from './navigation.module.scss'

const Navigation = ({ menuItems, closeNavigation}) => {
  return (
    <nav className={navigationStyles.navigation}>
      {menuItems.map((item, i) => (
        <MenuItem {...item} key={i} closeNavigation={closeNavigation}/>
      ))}
    </nav>
  )
}

export default Navigation
