import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'


const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

const iconName = icon => `fa${capitalize(icon)}`

const importedIcons = {
    faTwitter, faFacebook, faInstagram, faYoutube, faLinkedin
}


const getIcon = icon => importedIcons[iconName(icon)]




const icons = [
    {
        label: 'Facebook',
        icon: 'facebook',
        href: '#',
    },
    {
        label: 'Instagram',
        icon: 'instagram',
        href: '#',
    },
]

const SocialIcons = () => {
    if (icons.length === 0) {
        return null
    } 
    return  <ul className="icons left">
    {
        icons.map(({label,href, icon}, i) => <li key={i}><a href={href} className="icon" aria-label={label}><FontAwesomeIcon icon={getIcon(icon)} /><span className="label">{label}</span></a></li>)
    }
    </ul>
}

export default SocialIcons