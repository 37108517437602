
const menuItemsEnglish = [
    {
      label: 'Products',
      to: '/produkty',
    },
    {
      label: 'Services',
      to: '/sluzby',
    },
    {
      label: 'Technology',
      to: '/technologia',
    },
    {
      label: 'Engineering',
      to: '/inziniering',
    },
    {
      label: 'Gallery',
      to: '/galeria',
    },
    {
      label: 'About',
      to: '/#banner',
    },
    {
      label: 'Contact',
      to: '/#contact',
    },
  ]

  const menuItemsSlovak = [
    {
      label: 'Produkty',
      to: '/produkty',
    },
    {
      label: 'Služby',
      to: '/sluzby',
    },
    {
      label: 'Technológia',
      to: '/technologia',
    },
    {
      label: 'Inžiniering',
      to: '/inziniering',
    },
    {
      label: 'Galéria',
      to: '/galeria',
    },
    {
      label: 'O firme',
      to: '/#banner',
    },
    {
      label: 'Kontakt',
      to: '/kontakt',
    },
  ]

  const menuItemsMagyar = [
    {
      label: 'Otthon',
      to: '/',
    },
    {
      label: 'Termékek',
      to: '/produkty',
    },
    {
      label: 'Technológia',
      to: '/technologia',
    },
    {
      label: 'Mérnöki',
      to: '/inziniering',
    },
    {
      label: 'Galéria',
      to: '/galeria',
    },
    {
      label: 'A Cégről',
      to: '/#banner',
    },
    {
      label: 'Elérhetőség',
      to: '/#contact',
    },
  ]

  const menuItems = {
      'sk': menuItemsSlovak,
      'en': menuItemsEnglish,
      'hu': menuItemsMagyar
  }

  export default menuItems