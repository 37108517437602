import React from 'react'
import PropTypes  from 'prop-types'
import { Link } from 'gatsby-plugin-i18next'

const Action = ({to, label}) => <ul className="actions">
    <li>
        <Link to={to} className="button next">{label}</Link>
    </li>
</ul>

Action.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

export default Action

