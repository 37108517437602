import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import LogoSVG from '../../assets/images/logo1.svg'

const Logo = ({showLabel}) => <Link to="/" className="logo"><img src={LogoSVG} className="image" alt='FRT'/>
            {
               showLabel && <span>Ferencz Racing Technology</span>
            }
        </Link>

Logo.defaultProps = {
    showLabel: false,
}

Logo.propTypes = {
    showLabel: PropTypes.bool,
}

export default Logo
