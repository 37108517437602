


const preconnect = [
    { rel: "preconnect", href:"https://maps.gstatic.com"},
    { rel: "preconnect", href:"https://fonts.googleapis.com"}
]

export const HELMET_DEFAULT = {
    title: 'FRT - Ferencz Racing Technology',
    meta: [
        { name: 'description', content: 'Sample' },
        { name: 'keywords', content: 'sample, something' },
        { name: 'robots', content: 'noindex' }
    ],
    link: [
        ...preconnect
    ]
}


export const FACEBOOK_OPEN_GRAPH = [
    
]


export const MAP_COORDINATES = {
    lat: 48.6868928,
    lng: 21.2691637,
}

export const MAPS_LINK = `https://www.google.com/maps/place/FERENCZ+JOZEF+FRT/@48.6868963,21.266975,17z/data=!3m1!4b1!4m5!3m4!1s0x473ee0faff7d94e5:0xf1a60eb6951b1e6e!8m2!3d48.6868928!4d21.2691637`



export const SOCIAL_ICONS = [
    {
        label: 'Twitter',
        icon: 'twitter',
        href: '#',
    },
    {
        label: 'Facebook',
        icon: 'facebook',
        href: '#',
    },
    {
        label: 'Instagram',
        icon: 'instagram',
        href: '#',
    },
    {
        label: 'Youtube',
        icon: 'youtube',
        href: '#',
    },
    {
        label: 'LinkedIn',
        icon: 'linkedin',
        href: '#',
    }
]

export const COPYRIGHT = ``