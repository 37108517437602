import React from 'react'
import Layout from './MainLayout'
import Banner from '../components/Banner'

const GenericLayout = ({banner, children , isLanding}) => <Layout>
    {
      isLanding && <Banner {...banner} isLanding/>
    }
    {children}
    </Layout>

GenericLayout.defaultProps = {
    isLanding: false,
}


export default GenericLayout