import React from 'react'
import Copyright from './Copyright';
import SocialIcons from './SocialIcons';

const Footer = () => (
    <footer id="footer">
        <div className="inner">
            <SocialIcons/>
            <Copyright />
        </div>
    </footer>
)

export default Footer
