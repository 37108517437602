import React from 'react'
import PropTypes from 'prop-types'
import Action from '../Action'

const getClassName = isLanding => isLanding ? 'style2' : 'major'

const Banner = ({title, content, isLanding, action}) => {
    const className = getClassName(isLanding)
    return <section id="banner" className={className}>
        <div className="inner">
            <header className="major">
                <h1>{title}</h1>
            </header>
            <div className="content" dangerouslySetInnerHTML={{__html: content}}/>
            {
                action && <Action {...action}/>
            }
        </div>
    </section>
}

Banner.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    isLanding: PropTypes.bool,
}

Banner.defaultProps = {
    title: '',
    content: '',
    isLanding: false,
}

export default Banner
