import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from "react-cookie-consent";

const cookieConsentProps = {
  buttonStyle: { 
      color: '#fff', 
      background: '#e74c3c', 
      padding: '0px 10px',
      margin: '10px',
      fontSize: '16px',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
      fontWeight: 'normal',
  },
  style: { background: 'rgba(43, 55, 59, 0.90)' },
  expires: 500,
}

const CookieBar = ({ buttonText, message }) => <CookieConsent buttonText={buttonText} {...cookieConsentProps} >{message}</CookieConsent>

CookieBar.defaultProps = {
  message:'',
  buttonText: '',
}

CookieBar.propTypes = {
    buttonText: PropTypes.string,
    message: PropTypes.string,
}

export default CookieBar