import React, { Fragment } from 'react'
import { Language } from 'gatsby-plugin-i18next';
import translationSwitchStyles from './translationswitch.module.scss'
import IconGlobe from './IconGlobe';
import { TwoStateSwitch } from './_components/TwoStateSwitch';

const LANGUAGE_LABELS = {
  'sk': 'Slovensky',
  'en': 'English',
  'hu': 'Magyar'
}

const getLanguageLabels = lng => LANGUAGE_LABELS[lng]

const getSecondLanguage = (availableLngs, lng) =>
  availableLngs.find(val => val !== lng)

const TranslationSwitch = ({ changeLng, lng, availableLngs }) => {
  const numOfLanguages = availableLngs.length
  
  // early exit 
  if (numOfLanguages <= 1 ) {
    return null
  }

    return (
      <Fragment>
        <IconGlobe />
        {numOfLanguages === 2 ? (
          <TwoStateSwitch onClick={() => changeLng(getSecondLanguage(availableLngs, lng))} label={getLanguageLabels(getSecondLanguage(availableLngs, lng))} /> 
        ) : (
          <Fragment>
            <label for="language" className={translationSwitchStyles.label}>
              Change language
            </label>
            <select
              id="language"
              className={translationSwitchStyles.translationSwitch}
              value={lng}
              aria-label="Change language"
              onChange={event => changeLng(event.target.value)}
            >
              {availableLngs.map((value, key) => (
                <option value={value} key={key}>
                  {getLanguageLabels(value)}
                </option>
              ))}
            </select>
          </Fragment>
        )}
      </Fragment>
    )
}

export default props => (
  <Language>{lngProps => <TranslationSwitch {...props} {...lngProps} />}</Language>
);